import React from 'react';
import "../Pages/Home.css";
function copyright() {
    return (
        <div>
            <p className = "Copywr">Copyright 2021 Sytec Technical Services,  Inc. All Rights Reserved.</p>
        </div>
    );
}

export default copyright;