import React from 'react';
import "./Home.css";
import headerimage from "../components/headercrt.jpg";
import Copyright from "../components/Copyright";
function Home() {
    return (
        <div className = "grey">
            <img src={headerimage} alt="Header" className ="Header"/>
        <div className = "Pg">
            <h1>Sytec</h1>
            <h2 className = "Ts">Technical Services</h2>
            <p className = "Par">
            Located in the heart of the Tampa Bay area Sytec Technical Services has been providing small business IT support since 2005.
Our work is backed by over 35 years of experience in the Information Technology fields of Cybersecurity, Network Design, Cloud Computing, and User Collaboration.
</p>
<h2>Why Sytec?</h2>
<p className = "Par">Whether you are adding to your current IT infrastructure, starting a brand new business, or are considering outsourcing all of your IT needs, Sytec Technical Services is committed to providing quality services at competitive pricing with no long term contracts.
</p>

 < p className = "Par">
 Sytec Technical Services can assist your company with all of its information technology support needs by providing professional, quality service.
  </p>

 < p className = "Par">
 More companies each day are finding that outsourcing their IT functions can help to reduce overall costs and provide the peace of mind that comes with knowing your computer systems and data will be working when you need them.
 </p>
 </div>
 <Copyright/>
        </div>
    );
}

export default Home;