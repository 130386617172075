import Navbar from './components/navbar';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Serv from "./Pages/Servsup";
import Contact from "./Pages/Contact";


function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Router>
      <Navbar />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Services" element={<Serv/>} />
      <Route path="/Contact" element={<Contact/>} />
      </Routes>
    </Router>
        
        
      </header>
    </div>
  );
}

export default App;