import React from 'react';
import {  Link } from "react-router-dom";
import "./navbar.css"
function Navbar() {
    return (
        <div className = "Stick">
        
		 <div class="left"> 
         
        <Link to="/" className = "Navbut">Home</Link>         
        <Link to="/Services" className = "Navbut">Services & Support</Link> 
        <Link to="/Contact" className = "Navbut">Contact</Link>  
        
		</div>
         
	
    

    </div>
    );
}

export default Navbar;