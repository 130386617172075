import React from 'react';
import "./Home.css";
import headerimage from "../components/headercrt.jpg";
import Copyright from "../components/Copyright";

function Contact() {
    return (
        <div className = "grey">
            <img src={headerimage} alt="Header" className ="Header"/>
            <div className = "Contactpg">
            <h1>Located In The Tampa Bay Area </h1>
            <div className = "Contact">
                <h2>Address: </h2>
                <p>6119 11th Ave S. <br/>
Gulfport, FL  33707</p>
                </div>
                <div className = "Contact">
                <h2>Phone: </h2>
                <p>727-542-8239</p>
                </div>
                <div className = "Contact">
                <h2>Fax: </h2>
                <p>727-235-7491</p>
                </div>
                <div className = "Contact">
                <h2>Email: </h2>
                <a href="mailto:support@sytec.us">support@sytec.us</a>
        </div>
        </div>
        <Copyright/>
                </div>
                

    );
}

export default Contact;