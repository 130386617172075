import React from 'react';
import "./Home.css";
import headerimage from "../components/headercrt.jpg";
import Copyright from "../components/Copyright";
function Servsup() {
    return (
        <div className = "grey">
            <img src={headerimage} alt="Header" className ="Header"/>
            <div className = "Pg">
            <h1>Services and Support</h1>
            <div className = "Pars">
<li>  Network and Cybersecurity solutions</li>
<li> Computer Systems and Network Operating Systems Configuration and Support </li>
<li> Wired and Wireless Network Design and Installation </li>
<li>  Internet and E-mail Systems Implementation and Support</li>
<li> Server and Data Backup Solutions</li>
<li>  End User Training and Help Desk Support</li>
<li>  Network Performance Testing and Enhancement</li>
<li>  Voice and Data Systems Integration</li>
<li>  Wide Area Network Configuration and Implementation</li>
<li> Secure Remote Access and Telecommuting Solutions</li>

</div>
        </div>
<Copyright/>
        </div>
    );
}

export default Servsup;